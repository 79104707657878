import { computed } from "vue";
import { useStore } from "vuex";

const StudentEditPersonalDetails = () => {
  const store = useStore();
  const timesZones = computed(
    () => store.getters["shared/getListings"].timesZones
  );
  const countrycodes = computed(
    () => store.getters["shared/getListings"].countrycodes
  );
  const genders = computed(() => store.getters["shared/getListings"].genders);
  const learningPath = computed(
    () => store.getters["shared/getListings"].learningPath
  );
  const spanishKnowledge = computed(
    () => store.getters["shared/getListings"].spanishKnowledge
  );
  return {
    timesZones,
    countrycodes,
    genders,
    learningPath,
    spanishKnowledge,
  };
};

export default StudentEditPersonalDetails;

<template>
  <user-information
    :isLoading="isLoading"
    :name="currentTeacher?.name"
    :lastname="currentTeacher?.lastname"
    :age="currentTeacher?.age"
    :gender="currentTeacher?.gender"
    :timeZone="currentTeacher?.timezone"
    :photo="currentTeacher.photo"
    :memberSince="currentTeacher?.member_since"
    :showRole="true"
    :showCardRole="true"
    :status="currentTeacher?.status"
    :role="'Teacher'"
    :goPreviousPage="
      () => {
        resetForm(), goPreviousPage(), resetCurrentTeacher();
      }
    "
    :changeRole="() => toggleTeacherChangeRole(true)"
  />

  <div class="mt-4 d-flex gap-3">
    <teacher-other-details-profile
      :isLoading="isLoading"
      :address="currentTeacher?.address"
      :phone="currentTeacher?.cellphone"
      :birthday="currentTeacher?.birth"
      :hireDate="currentTeacher?.hire_date"
      :scheduledOfJobDays="getscheduledOfJobDays(currentTeacher)"
      :scheduledOfJobHours="currentTeacher?.scheduled_of_jod"
      :teacherEditProfile="() => editProfile()"
      :data="currentTeacher"
    />

    <skeleton
      v-if="isLoading"
      class="border-r-10 w-100 skeleton-dark p-3"
      height="20rem"
    />
    <template v-else>
      <iframe
        v-if="isShowIframe"
        class="border-r-10 w-100"
        :src="
          'https://www.youtube.com/embed/' + idIframe + '?autoplay=1&mute=1'
        "
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        frameborder="0"
        allowfullscreen
      ></iframe>

      <div
        v-else
        class="
          w-100
          text-center
          d-flex
          align-items-center
          flex-column
          justify-content-center
          gap-3
          bg-primary-5
          border-r-10
        "
      >
        <span class="material-icons text-primary-1" style="font-size: 80px">
          priority_high
        </span>
        <p class="text-primary-1 fs-5 px-5 lh-sm">
          You need to add a video presentation. Click on edit profile to add it
        </p>
      </div>
    </template>
  </div>
  <div class="mt-4">
    <user-table-feedback-component
      :isLoading="isLoading"
      :comments="currentTeacher.history_lessons"
      :title="'Feedback from students'"
      :headersComments="headersComments"
    />
  </div>
</template>

<script>
import UserInformation from "@/shared/components/UserProfile/UserInformation";
import UserTableFeedbackComponent from "@/shared/components/UserProfile/UserTableFeedbackComponent";
import TeacherOtherDetailsProfile from "@/modules/admin/components/Members/Teachers/Profile/TeacherOtherDetailsProfile";
import { headers as headersComments } from "@/modules/admin/data/UI/commentsTableData";
import useTeacherAddOrUpdate from "@/modules/admin/composables/Members/Teachers/useTeacherAddOrUpdate";
import useTeacher from "@/modules/admin/composables/Members/Teachers/useTeacher";
import { onUpdated } from "vue";
export default {
  components: {
    UserInformation,
    UserTableFeedbackComponent,
    TeacherOtherDetailsProfile,
  },
  setup() {
    const {
      goPage,
      loadServices,
      teacher,
      currentTeacher,
      isLoading,
      toggleTeacherChangeRole,
      isShowIframe,
      idIframe,
      getscheduledOfJobDays
    } = useTeacher();
    loadServices();

    const { editProfile, resetForm, resetCurrentTeacher } =
      useTeacherAddOrUpdate();
    /* @click="toggleTeacherModal({ isOpen: true, status: 'add' })" */

    const goPreviousPage = () => {
      goPage();
    };

    onUpdated(() => {
      console.log(currentTeacher.value);
    })

    return {
      goPreviousPage,
      toggleTeacherChangeRole,
      headersComments,
      teacher,
      currentTeacher,
      goPreviousPage,
      getscheduledOfJobDays,
      isLoading,
      editProfile,
      resetForm,
      resetCurrentTeacher,
      isShowIframe,
      idIframe,
    };
  },
};
</script>

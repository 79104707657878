<template>
  <user-information :isLoading="isLoadingProfile" :name="student.name" :lastname="student.last_name"
    :photo="student.photo" :status="student.status" :levels="levelsInfo" :currentLevel="student.level"
    :showCardRole="true" :toggleUpgradeLevel="() => toggleUpgradeLevelModal(true)" :goPreviousPage="goPreviousPage" />
  <user-personal-details-component :name="student.name" :lastName="student.last_name" :timezone="student.timezone"
    :email="student.email" :cellphone="student.phone" :birth="formatDate(student.birth, 'american')"
    :phone_code_id=student?.phone_code_id :gender="student.gender" :spanish_knowledge_id="student.spanish_knowledge_id"
    :about_me="student.about_me" :learning_path_id="student.learning_path_id" :userId="student.id"
    :isLoading="isLoadingProfile" :currentAction="currentAction" :userRole="student.payment_status"
    :saveDetails="saveDetails" />
</template>

<script>
import useStudent from "@/modules/admin/composables/Members/Students/useStudentEditProfile";

import useStudents from "@/modules/admin/composables/Members/Students/useStudents";
import formatDate from "@/shared/utils/changeTexts/formatDate"

import UserInformation from "@/shared/components/UserProfile/UserInformation";
import UserPersonalDetailsComponent from "@/shared/components/UserProfile/UserPersonalDetailsComponent";
import UserGoogleCalendarComponent from "@/shared/components/UserProfile/UserGoogleCalendarComponent";
import UserStatisticsComponent from "@/shared/components/UserProfile/UserStatisticsComponent";

import useInformation from "@/modules/admin/composables/Members/Students/useInformation";

export default {
  components: {
    UserInformation,
    UserPersonalDetailsComponent,
    UserGoogleCalendarComponent,
    UserStatisticsComponent,
  },
  setup() {
    const { student, isLoadingProfile, currentAction, saveDetails, levelsInfo } =
      useStudent();
    const { goToPage } = useStudents();

    const goPreviousPage = () => {
      goToPage({
        name: "admin-members-students",
      });
    };
    const { toggleUpgradeLevelModal } = useInformation();

    return {
      student,
      toggleUpgradeLevelModal,
      levelsInfo,
      isLoadingProfile,
      currentAction,
      goPreviousPage,
      saveDetails,
      formatDate
    };
  },
};
</script>
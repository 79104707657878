<template>
  <div class="bg-white border-r-10 p-4 mt-4">
    <div class="d-flex align-items-center justify-content-between">
      <h1 class="fs-4 text-primary-0 fw-bold w-100">Personal details</h1>

      <div class="d-flex gap-3 align-items-center justify-content-end w-100">
        <button class="
            btn
            text-secondary-1
            border-secondary-1
            bg-hover-light-secondary-1
            p-2
            px-4
          " :disabled="isLoading || currentAction === 'updating'"
          v-if="currentAction === 'update' || currentAction === 'updating'" @click="currentAction = 'edit'">
          Cancel
        </button>
        <button class="
            btn
            bl-shadow
            text-white
            bg-secondary-1 bg-hover-secondary-1
            p-2
            px-4
          " v-if="currentAction === 'update' || currentAction === 'updating'" :disabled="currentAction === 'updating'"
          @click="() => {
              saveDetails(
                {
                  name,
                  last_name: lastName,
                  email,
                  cellphone,
                  my_goal_about,
                  my_goal_option,
                  birth: typeof birth === 'object' ? moment(birth).format('YYYY-MM-DD') : getFirstDate(birth),
                  gender,
                  phone_code_id,
                  spanish_knowledge_id,
                  learning_path_id,
                  timezone,
                  about_me
                },
                userId
              );
              currentAction = 'updating';
            }
            ">
          Save
        </button>

        <button :disabled="isLoading" class="
            btn
            text-secondary-1
            border-secondary-1
            bg-hover-light-secondary-1
            p-2
            px-4
          " v-if="currentAction === 'edit'" @click="currentAction = 'update'">
          <i class="pi pi-pencil me-2"></i>
          Edit profile
        </button>
      </div>
    </div>

    <div class="profile-grid gap-4 pb-3 mt-4">
      <div class="field-blue col-2">
        <label for="">Name(s)*</label>
        <input-text type="text" name="name" v-model="name" :disabled="isLoading || currentAction === 'edit'"
          v-if="!isLoading" />

        <skeleton borderRadius="10px" height="2.5rem" class="skeleton-dark" v-else />
      </div>
      <div class="field-blue col-2">
        <label for="">Last name(s)*</label>
        <input-text type="text" name="lastName" v-model="lastName" :disabled="isLoading || currentAction === 'edit'"
          v-if="!isLoading" />

        <skeleton borderRadius="10px" height="2.5rem" class="skeleton-dark" v-else />
      </div>

      <div class="field-blue col-2">
        <label for="">Time zone*</label>
        <dropdown optionLabel="timezone_change" optionValue="timezone_change" placeholder="Select a Time zone"
          name="timezone" :options="timesZones.data" :loading="timesZones.isLoading" :filter="true" v-model="timezone"
          :disabled="isLoading || currentAction === 'edit' || userRole === 'Lead'
            " v-if="!isLoading" />
        <skeleton borderRadius="10px" height="2.5rem" class="skeleton-dark" v-else />
      </div>

      <div class="field-blue col-2">
        <label for="">Email</label>

        <input-text type="email" name="email" v-model="email" v-if="!isLoading"
          :disabled="isLoading || currentAction === 'edit'" />
        <skeleton borderRadius="10px" height="2.5rem" class="skeleton-dark" v-else />
      </div>

      <div class="field-blue col-2">
        <label for="">Phone</label>

        <div class="d-flex gap-2">
          <template v-if="!isLoading">
            <dropdown optionLabel="dial_code" optionValue="id" placeholder="Select code" style="width: 230px"
              :options="countrycodes.data" :loading="countrycodes.isLoading" v-model="phone_code_id" :filter="true"
              :disabled="isLoading || currentAction === 'edit' || userRole === 'Lead'
                " name="value" />
            <input-text name="cellphone" type="number" class="w-100" :useGrouping="false" v-model="cellphone" :disabled="isLoading || currentAction === 'edit' || userRole === 'Lead'
              " />
          </template>

          <skeleton borderRadius="10px" height="2.5rem" class="skeleton-dark" v-else />
        </div>
      </div>

      <div class="field-blue col-2">
        <label>Birthday*</label>

        <Calendar id="icon" :showIcon="true" class="w-100" name="birth"
          :maxDate="new Date(store.getters['shared/currentTimeTimezone'])" dateFormat="MM/DD/YY" v-model="birth"
          v-if="!isLoading" :disabled="isLoading || currentAction === 'edit' || userRole === 'Lead'
            " />

        <skeleton borderRadius="10px" height="2.5rem" class="skeleton-dark" v-else />
      </div>
      <div class="field-blue col-2">
        <label>Gender*</label>
        <dropdown optionLabel="value" optionValue="value" placeholder="Select gender" name="value" :options="genders"
          v-model="gender" class="w-100" v-if="!isLoading" :disabled="isLoading || currentAction === 'edit' || userRole === 'Lead'
            " />
        <skeleton borderRadius="10px" height="2.5rem" class="skeleton-dark" v-else />
      </div>
    </div>
    <hr class="line-blue" />
    <div class="
        profile-grid profile-grid-2
        align-items-center
        field-blue
        gap-4
        pt-3
      ">
      <label for="">Your Spanish knowledge*</label>

      <dropdown optionLabel="name" optionValue="id" :options="spanishKnowledge.data" :loading="spanishKnowledge.isLoading"
        placeholder="Select your Spanish knowledge" name="spanishKnowledge" v-model="spanish_knowledge_id"
        v-if="!isLoading" :disabled="isLoading || currentAction === 'edit' || userRole === 'Lead'" />
      <skeleton borderRadius="10px" height="2.5rem" class="skeleton-dark" v-else />
      <label for="">Learning path*</label>
      <dropdown :options="learningPath.data" :loading="learningPath.isLoading" optionLabel="name" optionValue="id"
        placeholder="Select learning path" name="learning_path_id" v-model="learning_path_id" v-if="!isLoading"
        :disabled="isLoading || currentAction === 'edit' || userRole === 'Lead'" />
      <skeleton borderRadius="10px" height="2.5rem" class="skeleton-dark" v-else />
    </div>

    <div class="pt-4 field-blue">
      <label for="">About yourself</label>

      <Textarea class="w-100 mt-2" height="90%" rows="4" placeholder="Tell us about yourself. 
Why do you want to learn Spanish? 
What do you do for a living?
What are some of your interests; sports, books, music, movies and hobbies? 
Anything else you would like our teachers to know just write it here. " name="my_goal_about" v-model="about_me"
        :autoResize="false" v-if="!isLoading && currentAction !== 'updating'"
        :disabled="isLoading || currentAction === 'edit' || userRole === 'Lead'" />
      <skeleton borderRadius="10px" height="8rem" class="skeleton-dark" v-else />
    </div>
  </div>
</template>

<script>
import studentEditPersonalDetails from "@/shared/composables/StudentProfile/StudentEditPersonalDetails";
import moment from "moment"
import { useStore } from 'vuex'
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    lastName: {
      type: String,
      default: "",
    },
    timezone: {
      type: String,
      default: "",
    },
    email: {
      type: String,
    },
    phone_code_id: {
      type: String,
    },
    cellphone: {
      type: String,
    },
    birth: {
      type: String,
    },
    learning_path_id: {
      type: String,
    },
    gender: {
      type: String,
    },
    spanish_knowledge_id: {
      type: String,
    },
    about_me: {
      type: String,
    },
    userId: {
      type: String,
    },
    isLoading: {
      type: Boolean,
    },
    currentAction: {
      type: String,
    },
    userRole: {
      type: String,
    },
    saveDetails: {
      type: Function,
    },
  },
  setup(props) {
    const getFirstDate = (birth) => {
      const date_array = birth.split("/");
      const new_date = date_array[2] + "-" + date_array[0] + "-" + date_array[1];
      console.log(new_date);
      return moment(new_date).format("YYYY-MM-DD");
    }
    // const { timesZones, countrycodes, genders, spanishKnowledge } = StudentEditPersonalDetails();
    const store = useStore()
    return {
      ...studentEditPersonalDetails(),
      moment,
      getFirstDate,
      store
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  &-2 {
    grid-template-columns: 12rem 1fr;
  }
}

.col-2 {
  display: grid;
  grid-template-columns: 6rem 1fr;
  align-items: center;
  width: 100%;
}

button {
  max-width: 180px;
  width: 100%;
}
</style>

<template>
  <skeleton borderRadius="10px" height="20rem" class="skeleton-dark p-3" v-if="isLoading" />
  <div class="bg-white p-4 border-r-10 w-100" v-else>
    <div class="d-flex justify-content-between">
      <h1 class="text-primary-0 fw-bold fs-4 mb-0">Other profile details</h1>
      <div class="d-flex flex-column justify-center gap-3">
        <button class="
          btn
          text-secondary-1
          border-secondary-1
          bg-hover-light-secondary-1
          p-2
          px-4
          d-flex
          gap-2
          align-items-center
        " @click="teacherEditProfile">
          <i class="pi pi-pencil"></i>
          Edit profile
        </button>
        <router-link :to="'/admins/admin-members-teachers/scheduled-job/' + data.id" class="
              btn
              text-primary-1 text-hover-primary-1
              bg-hover-primary-6
              border-primary-1
            ">
          Edit schedule
        </router-link>
      </div>

    </div>
    
    <div class="d-flex gap-3 mb-2">
      <p class="fw-bold fs-6">Address:</p>
      <p class="fs-6">
        {{ address ?? "-" }}
      </p>
    </div>
    <div class="d-flex gap-3 mb-2">
      <p class="fw-bold fs-6">Phone:</p>
      <p class="fs-6">
        {{ phone ?? "-" }}
      </p>
    </div>
    <div class="d-flex gap-3 mb-2">
      <p class="fw-bold fs-6">Birthday:</p>
      <p class="fs-6">
        {{ birthday ? formatDate(birthday, 'american') : "-" }}
      </p>
    </div>
    <div class="d-flex gap-3 mb-2">
      <p class="fw-bold fs-6">Hire date:</p>
      <p class="fs-6">
        {{ hireDate ? formatDate(hireDate, 'american') : "-" }}
      </p>
    </div>
  </div>
</template>
  
<script>
import formatDate from "@/shared/utils/changeTexts/formatDate";
export default {
  props: {
    scheduledOfJobDays: {
      type: Array,
    },
    scheduledOfJobHours: {
      type: String,
    },
    address: {
      type: String,
    },
    phone: {
      type: String,
    },
    birthday: {
      type: String,
    },
    hireDate: {
      type: String,
    },
    teacherEditProfile: {
      type: Function,
      default: () => { },
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: {}
    },
  },
  setup() {
    return {
      formatDate,
    };
  },
};
</script>
import { computed, onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { ref as refDb, set, get, child, onValue } from "firebase/database";
import database from "@/firebase/config";

const useStudentEditProfile = () => {
  const route = useRoute();
  const { id } = route.params;
  const isLoadingProfile = ref(true);
  const levelsInfo = ref([]);
  const currentAction = ref("edit");
  const student = ref({
    information: {
      name: "",
      email: "",
    },
  });

  const store = useStore();
  const listings = computed(() => store.getters["shared/getListings"]);

  const getStudent = async () => {
    isLoadingProfile.value = true;
    const { success, json } = await store.dispatch("admins/getStudentById", id);
    isLoadingProfile.value = false;
    if (success) {
      student.value = {
        ...json.information,
      };
      store.commit("admins/upgradeLevel", { data: json.information });
      getStatusStudent(json.information.token_firebase);
      setLevels(json.subtitles);
    }
  };

  onBeforeMount(async () => {
    await getStudent();
  });

  const getStatusStudent = async (token_firebase) => {
    const dbRefLastStatus = refDb(
      database,
      "Spanish/" + token_firebase + "/last_status"
    );
    const dbRefGet = refDb(database);
    onValue(dbRefLastStatus, (snapshot) => {
      if (snapshot.exists()) {
        if (snapshot.val() !== "online" && snapshot.val() !== "offline") {
          student.value.status = snapshot.val();
        } else {
          get(child(dbRefGet, "Spanish/" + token_firebase + "/status"))
            .then((snapshot2) => {
              student.value.status = snapshot2.val();
            })
            .catch((error) => {
              console.error(error);
            });
        }
      } else {
        student.value.status = "offline";
      }
    });

    const dbRefStatus = refDb(
      database,
      "Spanish/" + token_firebase + "/status"
    );
    onValue(dbRefStatus, (snapshot) => {
      if(snapshot.exists()) {
      if (snapshot.val() === "online") {
        get(child(dbRefGet, "Spanish/" + token_firebase + "/last_status"))
          .then((snapshot2) => {
            if (snapshot2.val() !== "online" && snapshot2.val() !== "offline") {
              student.value.status = snapshot2.val();
            } else student.value.status = "online";
          })
          .catch((error) => {
            console.error(error);
          });
      } else student.value.status = snapshot.val();
      }else{
        student.value.status = "offline";
      }
    });
  };

  const saveDetails = async (payload, userId) => {
    const { success, json } = await store.dispatch("admins/updateStudentLead", {
      payload,
      id: userId,
    });
    setTimeout(async () => {
      await getStudent();
    }, 1800);

    currentAction.value = "edit";
  };

  const setLevels = (subtitles) => {
    let levels_start = {};
    let levels = {};
    let levels_finish = {};
    let i = 0;
    subtitles?.forEach((title) => {
      let object = { ["" + i]: title };
      levels_start[title.level_id - 1] = {
        ...levels_start[title.level_id - 1],
        ...object,
      };
      i++;
    });
    Object.entries(levels_start).forEach(([key, value]) => {
      let level_info = {
        do_it: 0,
        total: 0,
        porcentage: 0,
        level_name_short: "",
        level_name: "",
      };
      Object.entries(value).forEach(([key2, value2]) => {
        if (value2.total_level_do === 1) {
          level_info.do_it = level_info.do_it + 1;
        }
        level_info.level_name_short = value2.level_name_short.toLowerCase();
        level_info.level_name = value2.level_name;
        level_info.total++;
      });
      level_info.porcentage = (level_info.do_it * 100) / level_info.total;
      levels[key] = level_info;
    });

    let initial_level = null;
    Object.entries(levels).forEach(([key, value]) => {
      if (initial_level === null) {
        initial_level = {
          ...value,
          initial_level_parent: value.level_name.substr(0, 7),
        };
        levels_finish[key] = initial_level;
      } else {
        if (
          initial_level.initial_level_parent !== value.level_name.substr(0, 7)
        ) {
          initial_level = {
            ...value,
            initial_level_parent: value.level_name.substr(0, 7),
          };
          levels_finish[key] = initial_level;
        }
      }
    });
    levelsInfo.value = levels_finish;
  };

  return {
    levelsInfo,
    setLevels,
    isLoadingProfile,
    student,
    listings,
    currentAction,
    saveDetails,
  };
};

export default useStudentEditProfile;

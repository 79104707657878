<template>
  <div class="bg-white border-r-10 p-4 w-100">
    <skeleton
      v-if="isLoading"
      borderRadius="10px"
      height="10rem"
      class="mb-3 skeleton-dark"
    />
    <template v-else>
      <h2 class="fs-5 text-primary-0 fw-bold">Your statistics in classes</h2>
      <div class="d-flex gap-3 mt-3 justify-content-between">
        <div class="bg-accent-3 px-3 py-2 rounded-3 border-accent-1 w-100">
          <p class="text-secondary-1 fs-3 fw-bold">
            {{ totalClasses }}
          </p>
          <p class="fs-6">Total classes so far.</p>
        </div>
        <div class="bg-accent-3 p-3 rounded-3 border-accent-1 w-100">
          <p class="text-secondary-1 fs-3 fw-bold">
            {{ totalClassesMonth }}
          </p>
          <p>This month.</p>
        </div>
        <div class="bg-accent-3 p-3 rounded-3 border-accent-1 w-100">
          <p class="text-secondary-1 fs-3 fw-bold">
            {{ totalClassesWeek }}
          </p>
          <p>This week.</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "UserStatisticsComponent",
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    totalClasses: {
      type: Number,
      default: 0,
    },
    totalClassesMonth: {
      type: Number,
      default: 0,
    },
    totalClassesWeek: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped></style>

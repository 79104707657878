import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import isValidateYTUrl from "@/shared/utils/validations/isValidateYTUrl";import { ref as refDb, set, get, child, onValue } from "firebase/database";
import database from "@/firebase/config";
import getYtUrlId from "@/shared/utils/getters/getYtUrlId";

const useTeacher = () => {
  const router = useRouter();
  const store = useStore();
  const route = useRoute();
  const params = route.params;
  const isUpdating = ref(false);
  const isLoading = ref(true);
  const teacherProfile = ref({});
  const payload = ref({
    id: "",
  });
  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const loadServices = async () => {
    isLoading.value = true;
    const { success, json } = await store.dispatch("admins/getTeacher", {
      id: params.id,
    });
    isLoading.value = false;
  };
  const isShowIframe = ref(false);
  const idIframe = ref("");

  const getStatusTeacher = async (token_firebase) => {
    const dbRefLastStatus = refDb(
      database,
      "Teachers/" + token_firebase + "/last_status"
    );
    const dbRefGet = refDb(database);
    onValue(dbRefLastStatus, (snapshot) => {
      if (snapshot.val() !== "online" && snapshot.val() !== "offline") {
        teacherProfile.value.status = snapshot.val();
      } else {
        get(child(dbRefGet, "Teachers/" + token_firebase + "/status"))
          .then((snapshot2) => {
            teacherProfile.value.status = snapshot2.val();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });

    const dbRefStatus = refDb(database, "Teachers/" + token_firebase + "/status");
    onValue(dbRefStatus, (snapshot) => {
      if (snapshot.val() === "online") {
        get(
          child(
            dbRefGet,
            "Teachers/" + token_firebase + "/last_status"
          )
        )
          .then((snapshot2) => {
            if (snapshot2.val() !== "online" && snapshot2.val() !== "offline") {
              teacherProfile.value.status = snapshot2.val();
            } else teacherProfile.value.status = "online";
          })
          .catch((error) => {
            console.error(error);
          });
      } else teacherProfile.value.status = snapshot.val();
    });
  };

  const currentTeacher = computed(() => {
    const teacher = store.getters["admins/getTeacher"].data;
    if (teacher.video) {
      const { status, id } = getYtUrlId(teacher.video);
      if (status === "success") {
        isShowIframe.value = isValidateYTUrl(
          "https://www.youtube.com/embed/" + id
        );
        idIframe.value = id;
      }
    } else {
      isShowIframe.value = false;
      idIframe.value = "";
    }
    teacherProfile.value = teacher

    getStatusTeacher(teacher.token_firebase);

    return teacherProfile.value;
  });
  const goPage = () => {
    router.push({ name: "admin-members-teachers" });
  };

  const teacher = computed(() => {
    return store.getters["admins/getTeacher"];
  });

  const teachersChangeRole = computed(() => {
    return store.getters["admins/changeRole"];
  });

  const listRoles = computed(() => {
    return store.getters["shared/getListings"].roles;
  });

  const updateRole = async () => {
    const new_params = route.params;
    router.push({ name: "admin-members-admins" });
    const { success } = await store.dispatch("admins/changeRole", {
      role_id: payload.value.id,
      id: new_params.id,
    });
    if (success) {
      toggleTeacherChangeRole(false);
      switch (payload.value.id) {
        case 1:
        case 2:
          router.push({ name: "admin-members-admins" });
          break;
        case 3:
          router.push({ name: "admin-members-teachers" });
          break;
        case 4:
          router.push({ name: "admin-members-students" });
      }
    }
    isUpdating.value = false;
  };

  const toggleTeacherChangeRole = (isOpen) => {
    store.commit("admins/changeRole", { isOpen });
  };

  const getscheduledOfJobDays = (teacher) => {
    if(teacher.days !== undefined){
      if(teacher.days.length > 0){
        if(teacher.days.length === 1){
          return [{day_start:days[0] , day_end: days[0]}];
        }else{
          let daysFound = []
          teacher.days = teacher.days.sort((a, b) => {
            return a -b
          })
          let initial = 1;
          let final = 7
          teacher.days.forEach((day, index) => {
            if(index > 0){
              if(day !== teacher.days[index - 1] + 1 && day !== teacher.days.length - 1){
                final = teacher.days[index - 1]
                daysFound.push({day_start:days[initial - 1] , day_end: days[final-1]})
                initial = day
                final = 7
              }
              if(index === teacher.days.length - 1){
                final = teacher.days[index]
                daysFound.push({day_start:days[initial - 1] , day_end: days[final-1]})
              }
            }
          })
          return daysFound
        }
      }
      return []
    }
    return []
  }

  return {
    toggleTeacherChangeRole,
    getscheduledOfJobDays,
    listRoles,
    teacher,
    payload,
    teachersChangeRole,
    loadServices,
    goPage,
    updateRole,
    isUpdating,
    teacherProfile,
    isLoading,
    currentTeacher,
    isShowIframe,
    idIframe,
  };
};
export default useTeacher;

<template>
  <div
    class="
      bg-white
      border-r-10
      p-4
      d-flex
      justify-content-center
      flex-column
      w-100
    "
  >
    <skeleton
      v-if="isLoading"
      borderRadius="10px"
      height="12rem"
      class="mb-3 skeleton-dark"
    />
    <template v-else>
      <h2 class="fs-5 text-primary-0 fw-bold">Your Google Calendar account</h2>
      <div class="mt-2 d-flex gap-4 align-items-center justify-content-center">
        <template v-if="isActiveGoogleCalendar">
          <button
            @click="unlinkAccount"
            class="
              btn
              text-white
              bg-secondary-1 bg-hover-secondary-1
              py-2
              px-4
              bl-shadow
            "
          >
            Unlink account
          </button>
        </template>
        <template v-else>
          <p>You haven't linked any account yet.</p>
          <button
            @click="linkAccount"
            class="
              btn
            "
          >
          <img src="/assets/images/btn_google_signin_light_normal_web.png" alt="">
          </button>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    isActiveGoogleCalendar: {
      type: Boolean,
      default: false,
    },
    unlinkAccount: {
      type: Function,
      default: () => {},
    },
    linkAccount: {
      type: Function,
      default: () => {},
    },
  },
};
</script>